import "./App.css";
import Books from "./components/Books";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import axios from "axios";

axios.defaults.baseURL = "https://booksbackend-3ngc.onrender.com/books";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Books />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";

const Books = () => {
    const [data, setData] = useState([]);

    useEffect(
        () => {
            axios.get()
            .then((res) => setData(res.data))
            .catch((err) => console.log(err));
        }, []
    );


    return(
        <div>
            {data.map(
                (each) => (
                    <div key={each._id}>
                    <h1>{each.title}</h1>
                    <br />
                    <h2>{each.author}</h2>
                    <br />
                    <p>{each.pages}</p>
                    </div>

                )
            )}
        </div>
    )
}
export default Books;